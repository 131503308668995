import React from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import {
  AcceptInvitation,
  Account,
  BindSlack,
  Login,
  MainPage,
  ResetPassword,
  Team,
  Topic,
} from "./pages";
import store from "./store";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/" element={<MainPage />}>
              <Route index element={<Topic />} />
              <Route path="account">
                <Route index element={<Account />} />
                <Route path="bind_slack" element={<BindSlack />} />
              </Route>
              <Route path="team">
                <Route index element={<Team />} />
                <Route path="join" element={<AcceptInvitation />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="topics">
                <Route index element={<Navigate to="/" />} />
                <Route path=":topicId" element={<Topic />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
);

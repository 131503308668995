import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Share as ShareIcon,
  Star as StarIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Button,
  ButtonGroup,
  Box,
  IconButton,
  TextField,
  Typography,
  Modal,
  Alert,
  AlertTitle,
  Link,
  CardHeader,
  Avatar,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ActionTypeChip } from "../components/ActionTypeChip";
import { TopicDeleteModal } from "../components/TopicDeleteModal";
import { UUIDTypography } from "../components/UUIDTypography";
import { updateMessage } from "../slices/messages";
import { getTopic, updateTopic } from "../slices/topics";
import {
  createTopicAction,
  deleteTopicAction,
  downloadTopicAsMISP,
  fetchMISP,
  updateTopicAction,
  uploadToMISP,
} from "../utils/api";
import { actionTypes, ZERO_FILLED_UUID } from "../utils/const";
import { findTopic, formatTimestamp } from "../utils/tools";

export function Topic() {
  const [topic, setTopic] = useState({
    abstract: "",
    actions: [],
    threat_level: 4,
    title: "",
    topic_id: "",
    updated_at: "",
  });

  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const topics = useSelector((state) => state.topics);

  const { topicId } = useParams();

  const isMobile = !useMediaQuery("(min-width:950px)");

  useEffect(() => {
    if (!topics || topics.length === 0) return;
    const foundTopic = findTopic(topics, topicId);
    if (foundTopic) setTopic(foundTopic);
  }, [topicId, topics]);

  // Title Edit
  const [editedTitle, setEditedTitle] = useState("");
  const [titleEditMode, setTitleEditMode] = useState(false);

  const handleChangeTitleEditMode = () => {
    setTitleEditMode(!titleEditMode);
    setEditedTitle(topic.title);
  };

  const handleEditTitle = (event) => setEditedTitle(event.target.value.trim());

  const handleChangeTitle = () => {
    if (editedTitle !== topic.title)
      dispatch(updateTopic({ topicId: topic.topic_id, topic: { title: editedTitle } }));
    handleChangeTitleEditMode();
  };

  // Download and Share
  const [openModal, setOpenModal] = useState(false);
  const [shareResult, setShareResult] = useState({
    status: "info",
    message: "",
    link: "",
  });

  const handleDownload = async (event) => {
    const mispJson = await downloadTopicAsMISP(topic.topic_id);
    const fileName = topic.topic_id + ".json";
    const data = new Blob([JSON.stringify(mispJson.data)], {
      type: "text/json",
    });
    //make download link and use it
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.download = fileName;
    link.href = jsonURL;
    link.click();
    document.body.removeChild(link);
  };

  const handleShare = async (event) => {
    const mispJson = await downloadTopicAsMISP(topic.topic_id);
    const result = await uploadToMISP(mispJson.data);
    if ("data" in result) {
      const data = result.data;
      if ("info" in data) {
        shareResult.status = "success";
        shareResult.message = data.info;
      }
      if ("errors" in data) {
        shareResult.status = "error";
        shareResult.message = data.errors[1].message;
      }
      const fetchJson = await fetchMISP(topic.topic_id);
      if ("url" in fetchJson.data) {
        shareResult.link = fetchJson.data.url;
      } else {
        shareResult.link = "";
      }
      setShareResult(shareResult);
      handleOpenModal();
    }
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  // Action
  const initAction = () => ({
    detail: {},
    template: "",
    type: "",
  });

  const [action, setAction] = useState(initAction());

  const handleChangeActionDetail = (event) =>
    setAction({
      ...action,
      detail: { ...action.detail, [event.target.id]: event.target.value },
    });

  const handleChangeActionTemplate = (event) =>
    setAction({ ...action, template: event.target.value });

  const handleChangeActionType = (event) => setAction({ ...action, type: event.target.value });

  const handleDeleteAction = async (actionId) => {
    await deleteTopicAction(actionId);
    dispatch(getTopic(topic.topic_id));
  };

  const handleRecommendAction = async (action) => {
    await updateTopicAction(action.action_id, {
      recommended: !action.recommended,
    });
    dispatch(getTopic(topic.topic_id));
  };

  const handleSaveAction = async (event) => {
    event.preventDefault();
    if (!action.template || !action.type) return;

    const actionText = actionTemplates[action.template].createText(action.detail);
    if (!actionText) return;
    const topicAction = {
      topic_id: topic.topic_id,
      action: actionText,
      action_type: action.type,
    };
    await createTopicAction(topicAction);
    dispatch(getTopic(topic.topic_id));
    setAction(initAction());
  };

  const parseField = (fieldName) => {
    const description = fieldName === "Description";
    return (
      <TextField
        fullWidth={description}
        id={fieldName}
        label={fieldName}
        multiline={description}
        onBlur={handleChangeActionDetail}
        required
        size="small"
        sx={{ my: 1, ...(!description && { mx: 1 }) }}
        variant="outlined"
      />
    );
  };

  const actionTemplates = {
    Update: {
      UI: (
        <Box alignItems="center" display="flex" flexDirection="row" flexGrow={1}>
          Update
          {parseField("Product Name")}
          to version
          {parseField("Patched Version")}
        </Box>
      ),
      createText: (detail) => {
        if (!detail["Product Name"] || !detail["Patched Version"]) {
          return "";
        } else {
          return `Update ${detail["Product Name"]} to version ${detail["Patched Version"]}`;
        }
      },
    },
    Patch: {
      UI: (
        <>
          <Box alignItems="center" display="flex" flexDirection="row" flexGrow={1}>
            Patch
            {parseField("Product Name")}
            according to the following instructions:
          </Box>
          {parseField("Description")}
        </>
      ),
      createText: (detail) => {
        if (!detail["Product Name"] || !detail["Description"]) {
          return "";
        } else {
          return `Patch ${detail["Product Name"]} according to the following instructions: ${detail["Description"]}`;
        }
      },
    },
    "Adopt Denylist": {
      UI: (
        <>
          Adopt the following settings to the Denylist of security appliance:
          {parseField("Description")}
        </>
      ),
      createText: (detail) => {
        if (!detail["Description"]) {
          return "";
        } else {
          return `Adopt the following settings to the Denylist of security appliance: ${detail["Description"]}`;
        }
      },
    },
    "Stop Service": {
      UI: (
        <Box alignItems="center" display="flex" flexDirection="row" flexGrow={1}>
          Stop the service using
          {parseField("Product Name")}
        </Box>
      ),
      createText: (detail) => {
        if (!detail["Product Name"]) {
          return "";
        } else {
          return `Stop the service using ${detail["Product Name"]}`;
        }
      },
    },
    Other: {
      UI: <>{parseField("Description")}</>,
      createText: (detail) => {
        if (!detail["Description"]) {
          return "";
        } else {
          return `${detail["Description"]}`;
        }
      },
    },
  };

  // Messages
  const handleRemoveMessage = (message) => {
    if (topic.abstract === message.msg_id)
      dispatch(
        updateTopic({
          topicId: topic.topic_id,
          topic: { abstract: ZERO_FILLED_UUID },
        }),
      );
    dispatch(
      updateMessage({
        msgId: message.msg_id,
        message: { topic_id: ZERO_FILLED_UUID },
      }),
    );
  };

  return (
    <>
      <Box alignItems="center" display="flex">
        {topic && titleEditMode ? (
          <>
            <TextField
              defaultValue={editedTitle}
              label="New Topic Title"
              onChange={handleEditTitle}
              required
              sx={{ flexGrow: 1 }}
            />
            <IconButton onClick={handleChangeTitleEditMode}>
              <UndoIcon />
            </IconButton>
            <IconButton onClick={handleChangeTitle}>
              <SaveIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography flexGrow={1} variant="h4">
                {topic.title}
              </Typography>
              <UUIDTypography>{topic.topic_id}</UUIDTypography>
            </Box>
            <IconButton onClick={handleChangeTitleEditMode}>
              <EditIcon />
            </IconButton>
            <TopicDeleteModal topicId={topic.topic_id} />
          </>
        )}
      </Box>
      <Box id="toolbar-area" sx={{ p: "10px" }}>
        <ButtonGroup variant="outlined" aria-label="button group">
          <Button onClick={handleDownload} startIcon={<DownloadIcon />}>
            Download
          </Button>
          <Button onClick={handleShare} startIcon={<ShareIcon />}>
            Share
          </Button>
        </ButtonGroup>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="share-result"
          aria-describedby="share-result-description"
        >
          <Alert
            severity={shareResult.status}
            id="share-report"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
            }}
          >
            <AlertTitle id="share-result">
              {shareResult.status === "success" ? "Upload Successful" : "Error"}
            </AlertTitle>
            <Typography id="share-result-description" mt={2}>
              {shareResult.link ? (
                <Link href={shareResult.link} underline="none" target="_blank" rel="noreferrer">
                  <strong>{shareResult.message}</strong>
                </Link>
              ) : (
                <strong>{shareResult.message}</strong>
              )}
            </Typography>
          </Alert>
        </Modal>
      </Box>
      <Box id="abstract-area" sx={{ p: "10px" }}>
        <Typography sx={{ color: "#555151" }}>Abstract</Typography>
        <Droppable droppableId="abstract">
          {(provided) => (
            <Box
              id="abstract"
              sx={{
                border: "2px dotted #000000",
                height: isMobile ? "" : "20vh",
                minHeight: "20vh",
                overflow: isMobile ? "" : "scroll",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {messages &&
                messages
                  .filter((message) => message.msg_id === topic.abstract)
                  .map((message) => (
                    <Box
                      key={`${message.timestamp}_${message.msg_id}`}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        className="delete-button"
                        onClick={() => handleRemoveMessage(message)}
                        sx={{
                          alignSelf: "flex-start",
                          display: "flex",
                          my: 2,
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                      <Box
                        flexDirection="column"
                        sx={{
                          p: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                              {message.slack_user.slice(0, 1)}
                            </Avatar>
                          }
                          subheader={formatTimestamp(message.timestamp)}
                          title={
                            <Link href={message.origin} rel="noreferrer" target="_blank">
                              {message.slack_user}
                            </Link>
                          }
                        />
                        <Typography variant="body">{message.text}</Typography>
                      </Box>
                    </Box>
                  ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
      <Box id="action-area" sx={{ p: "10px" }}>
        <Typography sx={{ color: "#555151" }}>Action</Typography>
        <Box className="action-area">
          <Box component="form">
            <TextField
              id="action-type-select"
              label="Action Type"
              onChange={handleChangeActionType}
              placeholder="Action Type"
              required
              select
              size="small"
              value={action.type}
              sx={{ mr: 1, my: 1, width: 200 }}
            >
              {actionTypes &&
                actionTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="Action Template"
              onChange={handleChangeActionTemplate}
              required
              select
              size="small"
              value={action.template}
              sx={{ my: 1, width: 300 }}
            >
              {Object.keys(actionTemplates).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </TextField>
            <Box display="flex" flexDirection="column">
              {action &&
                action.template &&
                actionTypes.includes(action.type) &&
                actionTemplates[action.template]["UI"]}
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
              <Button
                onClick={handleSaveAction}
                startIcon={<SaveIcon />}
                type="submit"
                variant="contained"
              >
                Save Action
              </Button>
            </Box>
          </Box>
          <List dense disablePadding>
            {actionTypes &&
              topic &&
              [...topic.actions]
                .sort(
                  (a, b) => actionTypes.indexOf(a.action_type) - actionTypes.indexOf(b.action_type),
                )
                .map((action) => (
                  <ListItem key={action.action_id}>
                    <IconButton onClick={() => handleDeleteAction(action.action_id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                    {action.recommended ? (
                      <Tooltip arrow placement="bottom" title="recommended">
                        <IconButton
                          color="warning"
                          onClick={() => handleRecommendAction(action)}
                          size="small"
                        >
                          <StarIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton onClick={() => handleRecommendAction(action)} size="small">
                        <StarIcon />
                      </IconButton>
                    )}
                    <ActionTypeChip actionType={action.action_type} />
                    <ListItemText primary={action.action} />
                  </ListItem>
                ))}
          </List>
        </Box>
      </Box>
      <Box id="topic-area" sx={{ p: "10px" }}>
        <Typography sx={{ color: "#555151" }}>Timeline</Typography>
        <Droppable droppableId="timeline">
          {(provided) => (
            <Box
              id="timeline"
              sx={{
                border: "2px dotted #000000",
                height: isMobile ? "" : "80vh",
                minHeight: "80vh",
                overflow: isMobile ? "" : "scroll",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Timeline>
                {messages &&
                  (() => {
                    const timelineMessages = messages
                      .filter((message) => message.topic_id === topic.topic_id)
                      .sort((a, b) => {
                        if (a.timestamp < b.timestamp) return -1;
                        else if (a.timestamp > b.timestamp) return 1;
                        else return 0;
                      });
                    return timelineMessages.map((message, index) => (
                      <TimelineItem
                        className="timeline-item"
                        key={`${message.timestamp}_${message.msg_id}`}
                      >
                        <IconButton
                          aria-label="undo"
                          className="undo-button"
                          onClick={() => handleRemoveMessage(message)}
                          sx={{
                            alignSelf: "flex-start",
                            display: "flex",
                          }}
                        >
                          <UndoIcon />
                        </IconButton>
                        <TimelineOppositeContent
                          className="timeline-date"
                          color="text.secondary"
                          style={{
                            flex: 0.1,
                            display: isMobile ? "none" : "",
                          }}
                        >
                          {formatTimestamp(message.timestamp)}
                          {topic.abstract === message.msg_id && "\n[Abstract]"}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {message.slack_user.slice(0, 1)}
                          </Avatar>
                          {timelineMessages.length - 1 !== index && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent sx={{ wordBreak: "break-all" }}>
                          <Link href={message.origin} rel="noreferrer" target="_blank">
                            {message.slack_user}
                          </Link>
                          {(() => {
                            if (isMobile)
                              return (
                                <Typography
                                  display="inline"
                                  ml={1}
                                  color="text.secondary"
                                  sx={{ fontSize: "0.875rem" }}
                                >
                                  {formatTimestamp(message.timestamp)}
                                </Typography>
                              );
                          })()}
                          <br />
                          <Typography variant="body">{message.text}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ));
                  })()}
                {provided.placeholder}
              </Timeline>
            </Box>
          )}
        </Droppable>
      </Box>
    </>
  );
}

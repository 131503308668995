import axios from "./axios";

const MESSAGES = "/messages";
const MISP = "/misp";
const TEAMS = "/teams";
const TOPICS = "/topics";
const USERS = "/users";

// Token
export const setToken = (token) =>
  (axios.defaults.headers.common["Authorization"] = `Bearer ${token}`);

export const removeToken = () => delete axios.defaults.headers.common["Authorization"];

// Messages
export const getMessages = async () => axios.get(MESSAGES);

export const updateMessage = async (msgId, data) => axios.put(`${MESSAGES}/${msgId}`, data);

export const deleteMessage = async (msgId) => axios.delete(`${MESSAGES}/${msgId}`);

// Misp
export const fetchMISP = async (topicId) => axios.get(`${MISP}/${topicId}`);

export const uploadToMISP = async (data) => axios.post(MISP, data);

// Teams
export const getMyTeam = async () => axios.get(TEAMS);

export const createTeam = async (data) => axios.post(TEAMS, data);

export const updateTeam = async (data) => axios.put(TEAMS, data);

export const getTeamAuthorities = async (teamId) => axios.get(`${TEAMS}/${teamId}/authority`);

export const getTeamInvited = async (invitationId) =>
  axios.get(`${TEAMS}/invitations/${invitationId}`);

export const createInvitation = async (data) => axios.post(`${TEAMS}/invitations`, data);

export const applyInvitation = async (data) => axios.post(`${TEAMS}/invitations/apply`, data);

// Topics
export const getTopic = async (topicId) => axios.get(`${TOPICS}/${topicId}`);

export const getTopics = async () => axios.get(TOPICS);

export const createTopic = async (data) => axios.post(TOPICS, data);

export const updateTopic = async (topicId, data) => axios.put(`${TOPICS}/${topicId}`, data);

export const deleteTopic = async (topicId) => axios.delete(`${TOPICS}/${topicId}`);

// TopicActions
export const createTopicAction = async (data) => axios.post(`${TOPICS}/actions`, data);

export const updateTopicAction = async (actionId, data) =>
  axios.put(`${TOPICS}/actions/${actionId}`, data);

export const deleteTopicAction = async (actionId) => axios.delete(`${TOPICS}/actions/${actionId}`);

// TopicMisp
export const downloadTopicAsMISP = async (topicId) => axios.get(`${TOPICS}/${topicId}/misp`);

// Users
export const getMyInfo = async () => axios.get(USERS);

export const createUser = async () => axios.post(USERS);

export const updateUser = async (data) => axios.put(USERS, data);

export const getSlackBinding = async (tokenId) => axios.get(`${USERS}/slack_binding/${tokenId}`);

export const applySlackBinding = async (data) => axios.post(`${USERS}/slack_binding/apply`, data);

export const deleteSlackBinding = async (slackId) =>
  axios.delete(`${USERS}/slack_binding/${slackId}`);

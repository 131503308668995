import { Edit as EditIcon, Undo as UndoIcon } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Chip, Divider, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UUIDTypography } from "../components/UUIDTypography";
import { getTeam } from "../slices/team";
import { getUser } from "../slices/user";
import { deleteSlackBinding } from "../utils/api";

export function Account() {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const team = useSelector((state) => state.team);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!loading || !user) return;
    setLoading(false);
    dispatch(getTeam());
  }, [dispatch, loading, user]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleDeleteSlackBinding = async (slackId) => {
    await deleteSlackBinding(slackId);
    dispatch(getUser());
  };

  return (
    team &&
    user && (
      <>
        <Box display="flex" flexDirection="row" flexGrow={1} mb={1}>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box alignItems="baseline" display="flex" flexDirection="row">
              <Typography variant="h4">{user.email.split("@")[0]}</Typography>
              <Typography color={grey[500]} variant="subtitle">
                {`@${user.email.split("@")[1]}`}
              </Typography>
            </Box>
            <UUIDTypography>{user.user_id}</UUIDTypography>
          </Box>
          <Box justifyContent="center" display="flex" flexDirection="column">
            <ButtonGroup>
              <Tooltip
                arrow
                placement="bottom-end"
                title={editMode ? "Exit Edit Mode" : "Edit Account Info"}
              >
                <Button color="warning" onClick={handleEditMode} variant="contained">
                  {editMode ? <UndoIcon /> : <EditIcon />}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
        <Divider />
        <Box alignItems="center" display="flex" flexDirection="row" mt={1}>
          <Box display="flex" flexDirection="row" width="30%">
            <Typography>Email:</Typography>
          </Box>
          <Box display="flex" flexDirection="row" width="70%">
            <Typography>{user.email}</Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row" mt={1}>
          <Box display="flex" flexDirection="row" width="30%">
            <Typography>User ID:</Typography>
          </Box>
          <Box display="flex" flexDirection="row" width="70%">
            <Typography>{user.user_id}</Typography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row" mt={1}>
          <Box display="flex" flexDirection="row" width="30%">
            <Typography>Team:</Typography>
          </Box>
          <Box alignItems="baseline" display="flex" flexDirection="row" width="70%">
            <Typography mr={1}>{team.team_name}</Typography>
            <UUIDTypography>{team.team_id}</UUIDTypography>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row" mt={1}>
          <Box display="flex" flexDirection="row" width="30%">
            <Typography>Slack ID:</Typography>
          </Box>
          <Box display="flex" flexDirection="row" width="70%">
            {user.slacks.length
              ? user.slacks
                  .map((slack) => slack.slack_id)
                  .sort()
                  .map((slackId) => (
                    <Chip
                      key={slackId}
                      label={slackId}
                      variant="outlined"
                      onDelete={editMode ? () => handleDeleteSlackBinding(slackId) : undefined}
                      sx={{ mr: 1 }}
                    />
                  ))
              : "-"}
          </Box>
        </Box>
      </>
    )
  );
}

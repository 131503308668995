import { Chip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { actionTypeChipColors, actionTypeChipWidth } from "../utils/const";

export function ActionTypeChip(props) {
  const { actionType } = props;
  return (
    <Chip
      color={actionTypeChipColors[actionType]}
      label={actionType}
      variant="outlined"
      sx={{ mr: 1, width: actionTypeChipWidth }}
    />
  );
}

ActionTypeChip.propTypes = {
  actionType: PropTypes.string.isRequired,
};

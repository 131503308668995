import { green } from "@mui/material/colors";

export const actionTypeChipWidth = 100;
export const messageDrawerWidth = 350;
export const topicDrawerWidth = 240;

export const ZERO_FILLED_UUID = "00000000-0000-0000-0000-000000000000";

export const actionTypeChipColors = {
  elimination: "error",
  mitigation: "warning",
  detection: "success",
  transfer: "info",
  acceptance: "primary",
  rejection: "secondary",
};
export const actionTypes = Object.keys(actionTypeChipColors);

export const commonButtonStyle = {
  bgcolor: green[700],
  color: "white",
  textTransform: "none",
  "&:hover": {
    bgcolor: green[900],
  },
};

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSnackbar } from "../slices/system";

export function SystemSnackbar() {
  /* usage:
   *   import { setSnackbar } from /path/to/slices/system;
   *   dispatch(setSnackbar({data}));  // to open
   *   dispatch(setSnackbar(null));  // force close
   *
   *  data: {
   *    message: "your message",
   *    autoHideDuration: duration in milliseconds, default is null(never).
   *    severity: "error"|"warning"|"info"|"success"
   *  }
   */

  const dispatch = useDispatch();
  const system = useSelector((state) => state.system);

  const handleClose = () => dispatch(setSnackbar(null));

  return system?.snackbar ? (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={system?.snackbar ? true : false}
        autoHideDuration={system?.snackbar?.autoHideDuration ?? null}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClick={handleClose}
          severity={system?.snackbar?.severity}
          sx={{ cursor: "pointer" }}
        >
          {system?.snackbar?.message}
        </MuiAlert>
      </Snackbar>
    </>
  ) : (
    <></>
  );
}

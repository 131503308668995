import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getMyInfo as getDBUser, updateUser as updateDBUser } from "../utils/api";

export const getUser = createAsyncThunk(
  "user/get",
  async () => await getDBUser().then((response) => response.data),
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data) => await updateDBUser(data).then((response) => response.data),
);

const userSlice = createSlice({
  name: "user",
  initialState: null,
  reducers: {
    clearUser: (state, action) => null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (_state, action) => action.payload)
      .addCase(updateUser.fulfilled, (_state, action) => action.payload);
  },
});

const { actions, reducer } = userSlice;

export const { clearUser } = actions;

export default reducer;

import { Delete as DeleteIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getMessages } from "../slices/messages";
import { getTopics } from "../slices/topics";
import { deleteTopic } from "../utils/api";

export function TopicDeleteModal(props) {
  const { topicId } = props;

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    await deleteTopic(topicId)
      .then(() => {
        // TODO show success message
        handleClose();
        dispatch(getMessages());
        dispatch(getTopics());
        navigate("/");
      })
      .catch(() => {
        // TODO show error message
      });
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>
          <Box alignItems="center" display="flex" flexDirection="row" flexGrow={1}>
            <Typography flexGrow={1} variant="inherit">
              Delete Topic
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box m={1}>
            Are you sure you want to delete the Topic and all linked Actions and Messages?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

TopicDeleteModal.propTypes = {
  topicId: PropTypes.string.isRequired,
};

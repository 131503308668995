import {
  AccountCircle as AccountCircleIcon,
  Add as AddIcon,
  ArrowForward as ArrowForwardIcon,
  CheckBox as CheckBoxIcon,
  Delete as DeleteIcon,
  DoneAll as DoneAllIcon,
  Groups as GroupsIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Message as MessageIcon,
  Topic as TopicIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CssBaseline,
  Divider,
  Drawer,
  Fab,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { DrawerHeader } from "../components/DrawerHeader";
import { MainStyle } from "../components/MainStyle";
import { SystemSnackbar } from "../components/SystemSnackbar";
import { TopicCreateModal } from "../components/TopicCreateModal";
import { UUIDTypography } from "../components/UUIDTypography";
import { clearMessages, deleteMessage, getMessages, updateMessage } from "../slices/messages";
import { clearTeam } from "../slices/team";
import { clearTopics, getTopics, updateTopic } from "../slices/topics";
import { clearUser, getUser } from "../slices/user";
import { getMyInfo, removeToken, setToken } from "../utils/api";
import { messageDrawerWidth, topicDrawerWidth, ZERO_FILLED_UUID } from "../utils/const";
import { findTopic, formatTimestamp } from "../utils/tools";

import { authCookieName, cookiesOptions } from "./Login";

export function MainPage() {
  const [loggingOut, setLoggingOut] = useState(false);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [topic, setTopic] = useState({
    abstract: "",
    actions: [],
    threat_level: 4,
    title: "",
    topic_id: "",
    updated_at: "",
  });
  const [openTopicsDrawer, setOpenTopicsDrawer] = useState(true);
  const [openMessagesDrawer, setOpenMessagesDrawer] = useState(true);
  const [isDrag, setIsDrag] = useState(false);

  /* eslint-disable-next-line no-unused-vars */
  const [cookies, _setCookie, removeCookie] = useCookies();

  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const topics = useSelector((state) => state.topics);
  const user = useSelector((state) => state.user);

  const location = useLocation();
  const navigate = useNavigate();
  const { topicId } = useParams();

  const theme = useTheme();

  const [onlyMyStockedMsg, setOnlyMyStockedMsg] = useState(false);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [selectMode, setSelectMode] = useState(false);
  const [showNewTopicModal, setShowNewTopicModal] = useState(false);

  const isMobile = !useMediaQuery("(min-width:950px)");

  useEffect(() => {
    if (loggingOut) return;
    if (!cookies) return;
    const _checkToken = async () => {
      try {
        if (!cookies[authCookieName]) throw new Error("Missing cookie");
        await setToken(cookies[authCookieName]);
        await getMyInfo()
          .then((ret) => {
            if (user?.user_id !== ret.data.user_id) {
              dispatch(getUser());
              dispatch(getMessages());
              dispatch(getTopics());
            }
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        await Promise.all([
          dispatch(clearUser()),
          dispatch(clearTeam()),
          dispatch(clearTopics()),
          dispatch(clearMessages()),
          removeCookie(authCookieName, cookiesOptions),
          removeToken(),
        ]);
        navigate("/login", {
          state: {
            from: location.pathname,
            search: location.search,
            message: "Please login to continue.",
          },
        });
      }
    };
    _checkToken();
  }, [cookies, dispatch, location, loggingOut, navigate, removeCookie, user]);

  useEffect(() => {
    if (!topics || topics.length === 0) return;
    const foundTopic = findTopic(topics, topicId);
    if (foundTopic) setTopic(foundTopic);
    else navigate("/");
  }, [navigate, topicId, topics]);

  useEffect(() => {
    setOpenTopicsDrawer(!isMobile);
    setOpenMessagesDrawer(!isMobile);
  }, [isMobile]);

  // App Bar
  const handleNavigateAccount = () => navigate("/account");
  const handleNavigateTeam = () => navigate("/team");
  const handleNavigateTop = () => navigate("/");
  const handleLogout = async () => {
    await setLoggingOut(true);
    await Promise.all([
      dispatch(clearUser()),
      dispatch(clearTeam()),
      dispatch(clearTopics()),
      dispatch(clearMessages()),
      removeCookie(authCookieName, cookiesOptions),
      removeToken(),
    ]);
    navigate("/login", {
      state: { message: "Logged out successfully." },
    });
  };
  const handleOpenAccountMenu = (event) => setAccountMenuAnchor(event.currentTarget);
  const handleCloseAccountMenu = () => setAccountMenuAnchor(null);
  const handleOpenDrawer = () => {
    setOpenTopicsDrawer(!openTopicsDrawer);
    setOpenMessagesDrawer(isMobile ? false : !openMessagesDrawer);
  };

  // Topics
  const changeTopic = (topic) => navigate(`/topics/${topic.topic_id}`);

  // Messages
  const handleOnlyMyStockedMsg = () => {
    setSelectedMessageIds([]);
    setOnlyMyStockedMsg(!onlyMyStockedMsg);
  };

  const handleSelectMode = () => {
    setSelectedMessageIds([]);
    setSelectMode(!selectMode);
  };

  const handleBulkAddMessages = () => {
    messages
      .filter((message) => selectedMessageIds.includes(message.msg_id))
      .forEach((message) =>
        dispatch(
          updateMessage({
            msgId: message.msg_id,
            message: { topic_id: topic.topic_id },
          }),
        ),
      );
    handleSelectMode();
  };

  const handleDeleteMessage = (message) => dispatch(deleteMessage(message.msg_id));

  const handleOnDragEnd = (result) => {
    setIsDrag(false);
    const droppableId = result?.destination?.droppableId;
    if (!droppableId || droppableId === "messages") return;
    const message = messages.find((message) => message.msg_id === result.draggableId);
    switch (droppableId) {
      case "abstract":
        dispatch(
          updateTopic({
            topicId: topic.topic_id,
            topic: { abstract: message.msg_id },
          }),
        );
        dispatch(
          updateMessage({
            msgId: message.msg_id,
            message: { topic_id: topic.topic_id },
          }),
        );
        break;
      case "timeline":
        dispatch(updateTopic({ topicId: topic.topic_id, topic: {} }));
        dispatch(
          updateMessage({
            msgId: message.msg_id,
            message: { topic_id: topic.topic_id },
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleSelectedMessages = (msg_id) => {
    if (!msg_id) {
      if (selectedMessageIds.length === 0)
        setSelectedMessageIds(
          messages
            .filter(
              (message) =>
                message.topic_id === ZERO_FILLED_UUID &&
                (onlyMyStockedMsg === false || message.user_id === user.user_id),
            )
            .map((message) => message.msg_id),
        );
      else setSelectedMessageIds([]);
      return;
    }
    const index = selectedMessageIds.indexOf(msg_id);
    if (index === -1) setSelectedMessageIds([...selectedMessageIds, msg_id]);
    else {
      selectedMessageIds.splice(index, 1);
      setSelectedMessageIds([...selectedMessageIds]);
    }
  };

  const handleOpenMessages = () => {
    setOpenTopicsDrawer(false);
    setOpenMessagesDrawer(!openMessagesDrawer);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            aria-label="menu"
            color="inherit"
            edge="start"
            size="large"
            onClick={handleOpenDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="div" onClick={handleNavigateTop} variant="h6">
            Flashsense
          </Typography>
          <Box flexGrow={1} />
          <IconButton color="inherit" onClick={handleOpenAccountMenu} size="large">
            <AccountCircleIcon fontSize="inherit" />
          </IconButton>
          <Menu
            anchorEl={accountMenuAnchor}
            onClick={handleCloseAccountMenu}
            onClose={handleCloseAccountMenu}
            open={Boolean(accountMenuAnchor)}
          >
            <Box px={2} py={1}>
              <Typography>{user?.email}</Typography>
              <UUIDTypography>{user?.user_id}</UUIDTypography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <MenuItem onClick={handleNavigateAccount}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              Account
            </MenuItem>
            <MenuItem onClick={handleNavigateTeam}>
              <ListItemIcon>
                <GroupsIcon fontSize="small" />
              </ListItemIcon>
              Team
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        anchor="left"
        open={openTopicsDrawer}
        variant="persistent"
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: topicDrawerWidth,
          },
        }}
      >
        <Box position="fixed" width={topicDrawerWidth} zIndex={theme.zIndex.drawer + 1}>
          <DrawerHeader />
          <DrawerHeader>
            <Typography>Topics</Typography>
            <Button
              color="success"
              variant="contained"
              onClick={() => {
                setShowNewTopicModal(true);
              }}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </DrawerHeader>
          <Divider />
        </Box>
        <DrawerHeader />
        <DrawerHeader />
        <List>
          {topics &&
            topics.map((topic_) => {
              return (
                <ListItem
                  button
                  key={topic_.topic_id}
                  selected={topic_.topic_id === topic.topic_id}
                  onClick={() => changeTopic(topic_)}
                >
                  <ListItemIcon>
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText primary={topic_.title} />
                </ListItem>
              );
            })}
        </List>
      </Drawer>
      <DragDropContext
        onDragStart={() => {
          isMobile && setIsDrag(true);
        }}
        onDragEnd={handleOnDragEnd}
      >
        <Drawer
          anchor={isMobile ? "left" : "left"}
          open={openMessagesDrawer}
          variant="persistent"
          SlideProps={{
            onEntering: (node, isAppearing) => {
              node.style.transition = theme.transitions.create(["width", "transform"], {
                duration: "225ms",
              });
            },
          }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              left: isMobile ? "initial" : topicDrawerWidth,
              width: isMobile && isDrag ? 10 : messageDrawerWidth,
              transition: theme.transitions.create(["width"], {
                duration: "0.3s",
              }),
            },
          }}
        >
          <Box
            position={isMobile && isDrag ? "" : "fixed"}
            width={isMobile && isDrag ? 10 : messageDrawerWidth}
            zIndex={theme.zIndex.drawer + 1}
          >
            <DrawerHeader />
            <DrawerHeader>
              <Typography>Messages</Typography>
              <Switch
                title="only my stocked messages"
                checked={onlyMyStockedMsg}
                onChange={() => {
                  handleOnlyMyStockedMsg();
                }}
              ></Switch>
              {selectMode ? (
                <ButtonGroup>
                  <Button
                    color="warning"
                    onClick={() => handleSelectedMessages()}
                    title="All Select"
                    variant="contained"
                  >
                    <DoneAllIcon />
                  </Button>
                  <Button
                    color="success"
                    onClick={handleBulkAddMessages}
                    title="Add"
                    variant="contained"
                  >
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    color="error"
                    onClick={handleSelectMode}
                    title="Cancel"
                    variant="contained"
                  >
                    <UndoIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                <Button
                  color="warning"
                  variant="contained"
                  onClick={handleSelectMode}
                  startIcon={<CheckBoxIcon />}
                >
                  Select
                </Button>
              )}
            </DrawerHeader>
            <Divider />
          </Box>
          <DrawerHeader />
          <DrawerHeader />
          <Droppable droppableId="messages">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {messages &&
                  messages
                    .filter(
                      (message) =>
                        message.topic_id === ZERO_FILLED_UUID &&
                        (onlyMyStockedMsg === false || message.user_id === user.user_id),
                    )
                    .map((message, index) => {
                      return (
                        <Draggable
                          draggableId={message.msg_id}
                          index={index}
                          isDragDisabled={selectMode}
                          key={`${message.timestamp}_${message.msg_id}`}
                        >
                          {(provided) => (
                            <Box display="flex">
                              <Card
                                key={`${message.timestamp}_${message.msg_id}`}
                                onClick={() => {
                                  selectMode && handleSelectedMessages(message.msg_id);
                                }}
                                ref={provided.innerRef}
                                sx={{
                                  width: "100%",
                                  m: "20px",
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CardHeader
                                  action={
                                    selectMode && (
                                      <Box alignItems="center" display="flex" flexDirection="row">
                                        <Checkbox
                                          checked={selectedMessageIds?.includes(message.msg_id)}
                                          onClick={() => handleSelectedMessages(message.msg_id)}
                                        />
                                        <IconButton onClick={() => handleDeleteMessage(message)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )
                                  }
                                  avatar={
                                    <Avatar aria-label="recipe" sx={{ bgcolor: red[500] }}>
                                      {message.slack_user.slice(0, 1)}
                                    </Avatar>
                                  }
                                  subheader={formatTimestamp(message.timestamp)}
                                  title={
                                    <Link href={message.origin} rel="noreferrer" target="_blank">
                                      {message.slack_user}
                                    </Link>
                                  }
                                />
                                <CardContent sx={{ wordBreak: "break-all" }}>
                                  <Typography variant="body">{message.text}</Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Drawer>
        <MainStyle
          open={openTopicsDrawer}
          width={isMobile ? 0 : topicDrawerWidth + messageDrawerWidth}
        >
          <Outlet />
        </MainStyle>
      </DragDropContext>
      <TopicCreateModal show={showNewTopicModal} setShow={setShowNewTopicModal} />
      {isMobile && (
        <Fab
          onClick={handleOpenMessages}
          color="primary"
          aria-label="add"
          sx={{ zIndex: 1200, position: "fixed", bottom: 30, right: 30 }}
        >
          <MessageIcon />
        </Fab>
      )}
      <SystemSnackbar />
    </ThemeProvider>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const systemSlice = createSlice({
  name: "system",
  initialState: {
    snackbar: null,
  },
  reducers: {
    setSnackbar: (state, action) => ({
      ...state,
      snackbar: action.payload,
    }),
  },
});

const { actions, reducer } = systemSlice;

export const { setSnackbar } = actions;

export default reducer;

import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUser } from "../slices/user";
import { applySlackBinding, getSlackBinding } from "../utils/api";

export function BindSlack() {
  const [binding, setBinding] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("Checking token...");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const params = new URLSearchParams(useLocation().search);
  const tokenId = params.get("token");

  useEffect(() => {
    if (!loading || !user) return;
    setLoading(false);
    getSlackBinding(tokenId)
      .then((response) => {
        setMessage("");
        setBinding(response.data);
      })
      .catch(() => setMessage("Valid binding token required."));
  }, [tokenId, loading, user]);

  const handleAccept = async () => {
    setDisabled(true);
    setMessage("Sending request...");
    try {
      await applySlackBinding({ token_id: tokenId });
    } catch (error) {
      setDisabled(false);
      setMessage(`An error has occurred: ${error.response.data.detail || "Something went wrong."}`);
      return;
    }
    dispatch(getUser());
    setMessage(`Successfully connected the Slack ID ${binding.slack_id}! You can close this page.`);
  };

  return binding ? (
    <>
      <Typography variant="h6">Do you connect the Slack ID to your account?</Typography>
      <Typography>Slack ID: {binding.slack_id}</Typography>
      <Box alignItems="center" display="flex" flexDirection="row">
        <Button disabled={disabled} onClick={handleAccept} variant="contained" sx={{ my: 1 }}>
          Connect
        </Button>
        <Typography ml={1}>{message}</Typography>
      </Box>
    </>
  ) : (
    <Typography>{message}</Typography>
  );
}

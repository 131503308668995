import axios from "axios";

const INTERNAL_SERVER = "Something went wrong! Please try again later.";
const INTERNET_CONNECTION = "Please check your internet connection and try again!";

const DEFAULT_API_CONFIG = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 30000,
};

const instance = axios.create({
  ...DEFAULT_API_CONFIG,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message && error.message === "Network Error") {
      const errMess = error.message === "Network Error" ? INTERNET_CONNECTION : INTERNAL_SERVER;
      throw errMess;
    }

    throw error;
  },
);

export default instance;

import {
  Edit as EditIcon,
  PersonAdd as PersonAddIcon,
  Save as SaveIcon,
  Star as StarIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, TextField, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TeamInviteModal } from "../components/TeamInviteModal";
import { UUIDTypography } from "../components/UUIDTypography";
import { getTeam, updateTeam } from "../slices/team";
import { getTeamAuthorities } from "../utils/api";

export function Team() {
  const [authorities, setAuthorities] = useState(null);
  const [editInfo, setEditInfo] = useState({ teamName: "" });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showNewInvitationModal, setShowNewInvitationModal] = useState(false);

  const dispatch = useDispatch();
  const team = useSelector((state) => state.team);
  const user = useSelector((state) => state.user);

  const resetEditInfo = useCallback(() => setEditInfo({ teamName: team?.team_name }), [team]);

  useEffect(() => {
    if (!loading || !user) return;
    setLoading(false);
    dispatch(getTeam());
    getTeamAuthorities(user.team_id).then((response) => setAuthorities(response.data));
  }, [dispatch, loading, user]);

  useEffect(() => {
    if (!team) return;
    resetEditInfo();
  }, [resetEditInfo, team]);

  const handleSave = () => {
    dispatch(updateTeam({ team_name: editInfo.teamName }));
    handleEditMode();
  };

  const handleEditMode = () => {
    resetEditInfo();
    setEditMode(!editMode);
  };

  const handleChangeEditInfo = (prop) => (event) =>
    setEditInfo({ ...editInfo, [prop]: event.target.value });

  const checkAuthority = (userId, authority) => {
    const userAuthority = authorities.find((auth) => auth.user_id === userId);
    if (userAuthority) {
      return userAuthority.authorities.includes(authority);
    } else {
      const defaultAuthority = authorities.find((auth) => !auth.user_id);
      return defaultAuthority && defaultAuthority.authorities.includes(authority);
    }
  };

  return (
    authorities &&
    team && (
      <>
        <Box display="flex" flexDirection="row" flexGrow={1} mb={1}>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box alignItems="baseline" display="flex" flexDirection="row">
              {editMode ? (
                <TextField
                  fullWidth
                  label="Team Name"
                  margin="dense"
                  onChange={handleChangeEditInfo("teamName")}
                  value={editInfo.teamName}
                />
              ) : (
                <Typography variant="h4">{editInfo.teamName}</Typography>
              )}
            </Box>
            <UUIDTypography>{team?.team_id}</UUIDTypography>
          </Box>
          <Box justifyContent="center" display="flex" flexDirection="column" ml={1}>
            <ButtonGroup>
              {checkAuthority(user.user_id, "invite") && (
                <Tooltip arrow placement="bottom-end" title={"Create invite link"}>
                  <Button
                    color="primary"
                    onClick={() => setShowNewInvitationModal(true)}
                    variant="contained"
                  >
                    <PersonAddIcon />
                  </Button>
                </Tooltip>
              )}
              {checkAuthority(user.user_id, "authorize") && (
                <>
                  {editMode && (
                    <Tooltip arrow placement="bottom-end" title="Save">
                      <Button color="success" onClick={handleSave} variant="contained">
                        <SaveIcon />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip
                    arrow
                    placement="bottom-end"
                    title={editMode ? "Exit Edit Mode" : "Edit Account Info"}
                  >
                    <Button color="warning" onClick={handleEditMode} variant="contained">
                      {editMode ? <UndoIcon /> : <EditIcon />}
                    </Button>
                  </Tooltip>
                </>
              )}
            </ButtonGroup>
          </Box>
        </Box>
        <Divider />
        {team?.users &&
          [...team.users]
            .sort((a, b) => (a.email < b.email ? -1 : 1))
            .map((member) => (
              <Box alignItems="center" display="flex" flexDirection="row" key={member.user_id}>
                {checkAuthority(member.user_id, "authorize") ? (
                  <StarIcon color="warning" />
                ) : checkAuthority(member.user_id, "invite") ? (
                  <StarIcon color="primary" />
                ) : (
                  <StarIcon color="disabled" />
                )}
                <Box alignItems="baseline" display="flex" ml={1}>
                  <Typography mr={1} variant="h5">
                    {member.email}
                  </Typography>
                  <UUIDTypography>{member.user_id}</UUIDTypography>
                </Box>
              </Box>
            ))}
        <TeamInviteModal open={showNewInvitationModal} setOpen={setShowNewInvitationModal} />
      </>
    )
  );
}

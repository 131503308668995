import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  deleteMessage as deleteDBMessage,
  getMessages as getDBMessages,
  updateMessage as updateDBMessage,
} from "../utils/api";

const sortMessages = (messages) =>
  messages.sort((a, b) => {
    if (a.timestamp < b.timestamp) return 1;
    else if (a.timestamp > b.timestamp) return -1;
    const msgIdA = a.msg_id.split("-").map((part) => parseInt(part, 16));
    const msgIdB = b.msg_id.split("-").map((part) => parseInt(part, 16));
    return msgIdA < msgIdB ? -1 : 1;
  });

const updateAndSort = (state, action) =>
  sortMessages(
    [...state].map((message) =>
      message.msg_id === action.payload.msg_id ? action.payload : message,
    ),
  );

export const getMessages = createAsyncThunk(
  "messages/getAll",
  async () => await getDBMessages().then((response) => sortMessages(response.data)),
);

export const updateMessage = createAsyncThunk(
  "messages/update",
  async (data) =>
    await updateDBMessage(data.msgId, {
      ...data.message,
      updated_at: new Date().toISOString(),
    }).then((response) => response.data),
);

export const deleteMessage = createAsyncThunk("messages/delete", async (msgId) => {
  await deleteDBMessage(msgId);
  return msgId;
});

const messagesSlice = createSlice({
  name: "messages",
  initialState: null,
  reducers: {
    clearMessages: (state, action) => null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.fulfilled, (_state, action) => action.payload)
      .addCase(updateMessage.fulfilled, updateAndSort)
      .addCase(deleteMessage.fulfilled, (state, action) =>
        state.filter((message) => message.msg_id !== action.payload),
      );
  },
});

const { actions, reducer } = messagesSlice;

export const { clearMessages } = actions;

export default reducer;

export const findTopic = (topics, topicId) => {
  if (topicId) return topics.find((topic) => topic.topic_id === topicId);
  else if (topics.length > 0) return topics[0];
  else return undefined;
};

export const formatTimestamp = (timestamp) => {
  const dateObj = new Date(timestamp);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const date = dateObj.getDate().toString().padStart(2, "0");
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  return `${year}/${month}/${date} ${hours}:${minutes}`;
};

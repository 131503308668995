import { styled } from "@mui/material/styles";

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "width",
})(({ theme, open, width }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  ...(open && {
    marginLeft: `${width}px`,
    transition: theme.transitions.create("margin", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  }),
}));

export { MainStyle };

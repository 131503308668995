import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createTopic as createDBTopic,
  getTopic as getDBTopic,
  getTopics as getDBTopics,
  updateTopic as updateDBTopic,
} from "../utils/api";

const sortTopics = (topics) =>
  topics.sort((a, b) => {
    const updatedAtA = a.updated_at;
    const updatedAtB = b.updated_at;
    if (updatedAtA > updatedAtB) return -1;
    else if (updatedAtA < updatedAtB) return 1;
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    if (titleA < titleB) return -1;
    else if (titleA > titleB) return 1;
    const topicIdA = a.topic_id.split("-").map((part) => parseInt(part, 16));
    const topicIdB = b.topic_id.split("-").map((part) => parseInt(part, 16));
    return topicIdA < topicIdB ? -1 : 1;
  });

const updateAndSort = (state, action) =>
  sortTopics(
    state.map((topic) => (topic.topic_id === action.payload.topic_id ? action.payload : topic)),
  );

export const createTopic = createAsyncThunk(
  "topics/create",
  async (title) => await createDBTopic({ title }).then((response) => response.data),
);

export const getTopic = createAsyncThunk(
  "topics/get",
  async (topicId) => await getDBTopic(topicId).then((response) => response.data),
);

export const getTopics = createAsyncThunk(
  "topics/getAll",
  async () => await getDBTopics().then((response) => response.data),
);

export const updateTopic = createAsyncThunk(
  "topics/update",
  async (data) =>
    await updateDBTopic(data.topicId, {
      ...data.topic,
      updated_at: new Date().toISOString(),
    }).then((response) => response.data),
);

const topicsSlice = createSlice({
  name: "topics",
  initialState: [],
  reducers: {
    clearTopics: (state, action) => [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTopic.fulfilled, (state, action) => [action.payload, ...state])
      .addCase(getTopic.fulfilled, updateAndSort)
      .addCase(getTopics.fulfilled, (_state, action) => sortTopics(action.payload))
      .addCase(updateTopic.fulfilled, updateAndSort);
  },
});

const { actions, reducer } = topicsSlice;

export const { clearTopics } = actions;

export default reducer;

import { combineReducers } from "redux";

import messages from "./messages";
import system from "./system";
import team from "./team";
import topics from "./topics";
import user from "./user";

const rootReducer = combineReducers({ system, messages, team, topics, user });

export default rootReducer;

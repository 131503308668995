import { styled } from "@mui/material/styles";

const DrawerHeader = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  borderRight: `1px solid ${theme.palette.divider}`,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export { DrawerHeader };

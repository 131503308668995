import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUser } from "../slices/user";
import { applyInvitation, getTeamInvited } from "../utils/api";

export function AcceptInvitation() {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("Checking token...");
  const [team, setTeam] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const params = new URLSearchParams(useLocation().search);
  const invitationId = params.get("token");

  useEffect(() => {
    if (!loading || !user) return;
    setLoading(false);
    getTeamInvited(invitationId)
      .then((response) => {
        setMessage("");
        setTeam(response.data);
      })
      .catch(() => setMessage("Valid invitation token required."));
  }, [invitationId, loading, user]);

  const handleAccept = async () => {
    setDisabled(true);
    setMessage("Sending apply request...");
    try {
      await applyInvitation({ invitation_id: invitationId });
    } catch (error) {
      setDisabled(false);
      setMessage(`An error has occurred: ${error.response.data.detail || "Something went wrong."}`);
      return;
    }
    dispatch(getUser());
    setMessage(`Successfully joined ${team.team_name}! You can close this page.`);
  };

  return team ? (
    <>
      <Typography variant="h6">Do you accept the invitation to the team below?</Typography>
      <Typography>Team Name: {team.team_name}</Typography>
      <Typography>Team ID: {team.team_id}</Typography>
      <Box alignItems="center" display="flex" flexDirection="row">
        <Button disabled={disabled} onClick={handleAccept} variant="contained" sx={{ my: 1 }}>
          Accept
        </Button>
        <Typography ml={1}>{message}</Typography>
      </Box>
    </>
  ) : (
    <Typography>{message}</Typography>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createTeam as createDBTeam,
  getMyTeam as getDBTeam,
  updateTeam as updateDBTeam,
} from "../utils/api";

export const getTeam = createAsyncThunk(
  "team/get",
  async () => await getDBTeam().then((response) => response.data),
);

export const createTeam = createAsyncThunk(
  "team/create",
  async (data) => await createDBTeam(data).then((response) => response.data),
);

export const updateTeam = createAsyncThunk(
  "team/update",
  async (data) => await updateDBTeam(data).then((response) => response.data),
);

const teamSlice = createSlice({
  name: "team",
  initialState: null,
  reducers: {
    clearTeam: (state, action) => null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeam.fulfilled, (_state, action) => action.payload)
      .addCase(createTeam.fulfilled, (_state, action) => action.payload)
      .addCase(updateTeam.fulfilled, (_state, action) => action.payload);
  },
});

const { actions, reducer } = teamSlice;

export const { clearTeam } = actions;

export default reducer;

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { createTopic } from "../slices/topics";

export function TopicCreateModal(props) {
  const { show, setShow } = props;

  const initNewTopic = () => ({
    title: "",
  });

  const [topic, setTopic] = useState(initNewTopic());

  const dispatch = useDispatch();

  const handleChangeTitle = (event) => setTopic({ ...topic, title: event.target.value });

  const handleCreate = async () => {
    dispatch(createTopic(topic.title));
    handleHide();
  };

  const handleHide = () => setShow(false);

  return (
    <Dialog onClose={handleHide} open={show} fullWidth>
      <DialogTitle>
        <Box alignItems="center" display="flex" flexDirection="row" flexGrow={1}>
          <Typography flexGrow={1} variant="inherit">
            Create New Topic
          </Typography>
          <IconButton aria-label="close" onClick={handleHide}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={1}>
          <TextField
            fullWidth
            error={topic?.title?.length === 0}
            id="title"
            label="Title (required)"
            onChange={handleChangeTitle}
            required
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Cancel</Button>
        <Button variant="contained" onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TopicCreateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

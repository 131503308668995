import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setSnackbar } from "../slices/system";
import { createInvitation } from "../utils/api";
import { commonButtonStyle } from "../utils/const";

export function TeamInviteModal(props) {
  const { open, setOpen } = props;

  const dispatch = useDispatch();
  const [invitationLink, setInvitationLink] = useState(null);
  const tokenToLink = (token) =>
    `${window.location.origin}${process.env.PUBLIC_URL}/team/join?token=${token}`;
  const [data, setData] = useState({});

  useEffect(() => {
    if (!open) return;
    dispatch(setSnackbar(null));
    setInvitationLink(null);
    setData({
      expiration: moment().add(1, "hours").toDate(),
      max_uses: 0,
    });
  }, [dispatch, open]);

  const handleHide = () => {
    setOpen(false);
  };
  const handleCreate = async () => {
    function onSuccess(success) {
      setInvitationLink(tokenToLink(success.data.invitation_id));
      dispatch(
        setSnackbar({
          message: "Create new invitation succeeded",
          severity: "success",
          autoHideDuration: 3000,
        }),
      );
    }
    function onError(error) {
      dispatch(
        setSnackbar({
          message: `Create invitation failed: ${error.response?.data?.detail ?? error}`,
          severity: "error",
        }),
      );
    }
    const payload = {
      expiration: data.expiration.toISOString(),
      max_uses: data.max_uses || null,
    };
    await createInvitation(payload)
      .then((success) => onSuccess(success))
      .catch((error) => onError(error));
  };
  const now = moment();

  return (
    <Dialog fullWidth onClose={handleHide} open={open}>
      <DialogTitle>
        <Typography flexGrow={1} variant="inherit">
          Create New Invitation Link
        </Typography>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {invitationLink ? (
            <Box display="flex" flexDirection="column">
              <Typography>Please share the invitation link below:</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link href={invitationLink} sx={{ overflow: "auto", whiteSpace: "nowrap" }}>
                  {invitationLink}
                </Link>
                <IconButton onClick={() => navigator.clipboard.writeText(invitationLink)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Grid container alignItems="center">
              <Grid item p={1} xs={12} sm={6}>
                <DateTimePicker
                  inputFormat="YYYY/MM/DD HH:mm"
                  label="Expiration Date (future date)"
                  mask="____/__/__ __:__"
                  minDateTime={now}
                  onChange={(moment) =>
                    setData({ ...data, expiration: moment ? moment.toDate() : "" })
                  }
                  renderInput={(params) => (
                    <TextField fullWidth margin="dense" required {...params} />
                  )}
                  value={data.expiration}
                />
              </Grid>
              <Grid item p={1} xs={12} sm={6}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Typography>Max uses: {data.max_uses || "unlimited"}</Typography>
                  <Box mx={1}>
                    <Slider
                      step={1}
                      marks={[0, 1, 5, 10, 15, 20].map((item) => ({
                        label: item || "\u221e", // infinity
                        value: item,
                      }))}
                      max={20}
                      min={0}
                      onChange={(_, value) => setData({ ...data, max_uses: value })}
                      value={data.max_uses}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide} sx={commonButtonStyle}>
          {invitationLink ? "Close" : "Cancel"}
        </Button>
        {!invitationLink && (
          <Button
            onClick={handleCreate}
            disabled={!now.isBefore(data.expiration)}
            sx={commonButtonStyle}
          >
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

TeamInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
